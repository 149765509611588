<template>
  <div id="bug-report-add">
    
    <ValidationObserver ref="formAdd">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <!--begin::Form-->
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
				<h3 class="card-label font-weight-bolder text-dark">Lapor Bug</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Laporan Bug pada aplikasi disertai foto/video</span>
              </div>
          </div>
          <!--end::Header-->

          <div class="card-body">
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Subjek</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Subjek" rules="required|max:50" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-bug-1" 
                              label-for="input-bug-1"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                                id="input-bug-1"
                                v-model="form.subjek"
                                class="form-control form-control-solid"
                                required
                                size="lg"
                                placeholder="Subjek Bug"
                                :invalid-feedback="errors[0]"
                                :state="valid"
                              ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Prioritas</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Prioritas" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-bug-2" 
                              label-for="input-bug-2"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-bug-2"
                              variant="solid"
                              size="lg"
                              :options="opsPrioritas"
                              v-model="form.prioritas"
                              required
                              :state="valid"
                              >
                              </b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Deskripsi</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Deskripsi" rules="required|min:5|max:200" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-2" 
                              label-for="input-se-2"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-textarea
                                id="input-se-2"
                                v-model="form.deskripsi"
                                class="form-control form-control-solid"
                                required
                                size="lg"
                                placeholder="Silahkan isi deskripsi sedetail mungkin"
                                :invalid-feedback="errors[0]"
                                :state="valid"
                              ></b-form-textarea>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>

              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Foto/Video</label>
                  <div class="col-lg-9 col-xl-7">
                    <a v-if="tempAttachments.length > 0" @click="removefiles" class="btn btn-light-primary font-weight-bold">Remove files</a>
                    <vue-dropzone
                      ref="myVueDropzone"
                      :include-styling="false"
                      :useCustomSlot="true"
                      id="dropzone"
                      @vdropzone-file-added="fileAdded"
                      @vdropzone-upload-progress="uploadProgress"
                      @vdropzone-success="uploadSuccess"
                      @vdropzone-error="uploadError"
                      :options="dropzoneOptions"
                    >
                      <a v-if="tempAttachments.length == 0" class="btn btn-light-primary font-weight-bold">Attach files</a>
                      <span v-else></span>
                    </vue-dropzone>
                    <span class="form-text text-muted">Ukuran file Maksimal 10MB (MegaByte) </span>

                    <div v-if="tempAttachments.length > 0" class="mt-3">  
                      <div v-for="(item, key) in tempAttachments" :key="key" class="alert alert-light" :class="{'cursor-pointer': !item.message}" @click="downloadFile(item)">
                        <p> {{ item.title }} ({{ (item.size/1000000).toFixed(2) }} MB) </p>
                        <b-progress height="7px" :value="item.progress" :max="100" show-progress animated></b-progress>
                        <span v-if="item.message" class="form-text text-danger">{{ item.message }}</span>
                      </div>
                    </div>
                  </div>
              </div>
        
          </div>
      

          <div class="card-footer">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-9">
                <button  
                  @click="onSubmit" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold"
                >
                  <i class="la la-send"></i>
                  Kirim
                </button>
                &nbsp;
                <button
                  @click="onReset"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
    
      <!--end::Form-->
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import BugReportService from "@/core/services/api/other/bugreport.service";
import ErrorService from "@/core/services/error.service";
import JwtService from "@/core/services/jwt.service";

import vue2Dropzone from 'vue2-dropzone'

export default {
  name: "bug-report-add",
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      form: {
        subjek: null,
        prioritas: null,
        deskripsi: null,
        file: null
      },
      tempAttachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL }/v2/bug-report/file`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        maxFilesize: 10,
        maxFiles: 1,
        acceptedFiles: 'video/*,image/*',
        includeStyling: false,
        previewsContainer: false,
        parallelUploads: 20
      },
      opsPrioritas: [{
            value: null,
            text: "Silahkan pilih Prioritas"
        }, {
            value: "low",
            text: "Low"
        }, {
            value: "medium",
            text: "Medium"
        }, {
            value: "high",
            text: "High"
        }, {
            value: "urgent",
            text: "Urgent"
        }
      ],
      show: true
    }
  },
  methods: {    
    removefiles () {
        this.$refs.myVueDropzone.removeAllFiles(true);
        this.form.file = null
        this.tempAttachments= [];
    },
    fileAdded(file) {      
      let attachment = {};
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = "file";
      attachment.extension = "." + file.type.split("/")[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      attachment.message = null;
      this.tempAttachments = [...this.tempAttachments, attachment];

    },
    uploadProgress(file, progress, bytesSent) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.progress = `${Math.floor(progress)}`;
        }
      });
    },
    uploadSuccess(file, response) {
      this.form.file = response.data

      ErrorService.message(response)
    },
    uploadError(file, message, xhr) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.message = message;
        }
      });
    },
    downloadFile(file) {
      if (file.filename && file.progress == 100 && !file.message) {
        window.open(`${process.env.VUE_APP_API_URL}/dokumen/bug-report/thum/${file.filename}`, "_blank"); 
      }
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            BugReportService.addBugReport(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.$router.push({ name: 'bug-report-histori' })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })
        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      this.form.subjek = null
      this.form.prioritas = null
      this.form.deskripsi = null
      this.form.file = null
      
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bug Report" }]);
  },
};
</script>